<template>
  <div>
    <h1>This page cannot be found.</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h1 {
  padding: 12.5rem;
}
</style>
