<template>
  <div class="svg-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1267.38 354.85"
      class="svg-content"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class="cls-1"
            :class="[{ secondary: altColor }]"
            d="M420.87,168.22H294.48c-1.82,14.84,9.45,30.23,22.93,31.94,12.07,1.54,23.42-.16,31.79-10.14,2.76-3.29,5.94-4,9.69-3.58,18.69,1.93,37.38,3.94,57.24,6-4.75,11.67-12.44,19.74-20.88,27.18C382.5,230.9,367,236.14,350.58,237.51c-19.84,1.64-39.85,2.46-59.7-1.08-27.55-4.91-46.56-21-57.14-46.23-10.32-24.56-10.25-50.22-.59-75,10.66-27.29,32.13-42.93,60.34-48.46,23.36-4.57,47-4,70.06,2.63,28.87,8.34,46.07,28,53.26,56.65C420.19,139.52,419.89,153.45,420.87,168.22ZM293.69,135.79h59.1c.73-19.32-9.65-31.5-26.63-31.9C306.18,103.43,294,115.17,293.69,135.79Z"
          />
          <path
            class="cls-2"
            :class="[{ secondary: altColor }]"
            d="M741,168.82h-127c.92,8.61,3.28,15.56,8.22,21.77,11,13.76,35.36,13.17,46.52,1.19,5.14-5.52,10.08-5.75,16.81-4.44,8.72,1.7,17.73,1.88,26.6,2.84,8.09.88,16.17,1.93,25.3,3-11,19.59-25.75,33.82-47.18,40.53A102.12,102.12,0,0,1,657,238.53c-8.66-.21-17.33-.12-26,0-40.91.38-70.56-24.31-80.5-60.15-7.12-25.7-2.68-57.65,12.32-78.94,9.46-13.45,22.07-22.83,37.49-28.64,21.08-8,42.71-8,64.52-4.69,9.12,1.39,18.54,2.57,27,5.89,27.14,10.62,42,31.63,47,59.83A143.08,143.08,0,0,1,741,168.82ZM674.65,136c-1.77-23.48-13.82-32.51-31.93-32.53-13.38,0-30.05,16.09-28.06,32.53Z"
          />
          <path
            class="cls-3"
            :class="[{ secondary: altColor }]"
            d="M1182.8,354.85c-29-.38-50.15-11.58-70-26.24-16.64-12.29-28.51-28.1-33.51-48.23-6.54-26.33-1.67-50.78,14.28-72.9,12.8-17.77,29.64-29.43,51.2-33.89,9.84-2,19.57-1.35,29.61-.15,28.72,3.43,51.15,18,70.63,37.9,23.33,23.85,28.74,59.64,14.7,89.86-11.84,25.47-29.73,44.11-57.62,51.22C1194.76,354.29,1187,354.35,1182.8,354.85Zm25-104.14c1.62-24.72-25.65-36.2-43.27-24.9-18,11.56-27.91,28.93-29.42,50.63a16.62,16.62,0,0,0,.1,3,28.79,28.79,0,0,0,35.06,25c10.46-2.39,17.53-9.12,23.3-17.56C1200.74,276.43,1207.69,265.83,1207.82,250.71Z"
          />
          <path
            class="cls-4"
            :class="[{ secondary: altColor }]"
            d="M515.21,116.2V233.91c-7.58,2.39-47.66,3.07-65.47,1.19-.58-9.61-.18-19.49-.25-29.34-.07-9.66,0-19.32,0-29V116.57H426V68.75h22.45c.94-9.37,1.77-17.83,2.65-26.29C453.92,15.18,471,2.12,497.11.62c15.45-.88,31.11-1.51,46.4,3.17,4.18,1.28,9.09.19,15,.19-2.45,13.89-4.68,26.49-7,39.48-6.87-.38-12.73-1-18.59-1-12.83,0-17.9,5.24-18,18.31,0,2,.2,4,.38,7.27h28.94V116.2Z"
          />
          <path
            class="cls-4"
            :class="[{ secondary: altColor }]"
            d="M827.53,86.34c3.45-4.46,6.35-9.53,10.46-13.27,12-10.93,39.55-11.65,52.61-2,3.88,2.86,3,6.37,1.65,9.8-2.73,6.79-5.73,13.47-8.66,20.18-2.64,6.07-5.33,12.12-8,18-6.79-1.13-12.89-2.55-19.07-3.07-8.46-.71-14.69,3.29-18.94,10.55a61,61,0,0,0-8.25,29.39c-.62,22.66-.34,45.33-.41,68,0,3.59,0,7.18,0,11.36H764.53c-1.9-7.26-2.48-152.83-.61-166.2h59.16c.23,5.78.45,11.33.67,16.89v4.77l1.31.25Z"
          />
          <path
            class="cls-5"
            :class="[{ secondary: altColor }]"
            d="M62.83,233.79H0V69.94c7.09-2,43.76-2.48,58.68-.73.18,3.3.44,6.8.53,10.31s0,7.23,0,13c2.53-3.11,4.05-4.56,5.07-6.3C79.49,60.36,102.79,60.57,125.8,71c4,1.8,4.06,4.8,2.53,8.32-5.55,12.8-11.08,25.62-17,39.42-3.75-1-7.19-1.77-10.55-2.8-11.68-3.58-22.28.26-28,11a50,50,0,0,0-5.57,15.89c-1.79,12.48-3.12,25.07-3.66,37.66-.6,14.31-.11,28.67-.16,43C63.34,226.77,63,230,62.83,233.79Z"
          />
          <path
            class="cls-6"
            :class="[{ secondary: altColor }]"
            d="M1110.22,130.19,1025,273.29c-19.6-10-37.1-21.84-56.48-32.87l85.36-143.37C1073.24,107.19,1091,118.67,1110.22,130.19Z"
          />
          <path
            class="cls-7"
            :class="[{ secondary: altColor }]"
            d="M200.81,69v166.4H138.76c-2.27-6.85-2.83-154.6-.53-166.4Z"
          />
          <path
            class="cls-8"
            d="M874,235.27V172.19c6.81-2.2,47.61-2.94,67.19-1.23,2.19,6.77,2.91,44,1.22,63.2C935.66,236.35,894.84,237.1,874,235.27Z"
          />
          <path
            class="cls-7"
            :class="[{ secondary: altColor }]"
            d="M137.4,49v-43c7-2.06,47.57-2.65,63.78-.79.07,6.91.18,14,.22,21.18s0,14.56,0,21.64C193.89,50.27,153.36,50.86,137.4,49Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    altColor: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
  z-index: -1;
}

.svg-content {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: auto;
  top: 0px;
  left: 0px;
  max-height: 630px;
}

@media screen and (min-width: 900px) {
  .svg-content {
    width: 70%;
  }
}

@media screen and (max-width: 497px) {
  .svg-content {
    width: 260px;
  }
}

.cls-1 {
  fill: #e77082;
}
.cls-2 {
  fill: #e4536a;
}
.cls-3 {
  fill: #ef8e9d;
}
.cls-4 {
  fill: #e66175;
}
.cls-5 {
  fill: #ef8d9c;
}
.cls-6 {
  fill: #eb7d8d;
}
.cls-7 {
  fill: #eb7b8c;
}
.cls-8 {
  fill: #e76f81;
}

.secondary {
  fill: #1abc9c;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
  transition: all 0.2s;
}

.cls-1:hover,
.cls-2:hover,
.cls-3:hover,
.cls-4:hover,
.cls-5:hover,
.cls-6:hover,
.cls-7:hover,
.cls-8:hover {
  fill: #f25f5c;
}
</style>
